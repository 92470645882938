.cesium-svgPath-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.cesium-button {
  display: inline-block;
  position: relative;
  background: #303336;
  border: 1px solid #444;
  color: #edffff; /* For text buttons */
  fill: #edffff; /* For SVG buttons */
  border-radius: 4px;
  padding: 5px 12px;
  margin: 2px 3px;
  cursor: pointer;
  overflow: hidden;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cesium-button:focus {
  color: #fff; /* For text buttons */
  fill: #fff; /* For SVG buttons */
  border-color: #ea4;
  outline: none;
}

.cesium-button:hover {
  color: #fff; /* For text buttons */
  fill: #fff; /* For SVG buttons */
  background: #48b;
  border-color: #aef;
  box-shadow: 0 0 8px #fff;
}

.cesium-button:active {
  color: #000; /* For text buttons */
  fill: #000; /* For SVG buttons */
  background: #adf;
  border-color: #fff;
  box-shadow: 0 0 8px #fff;
}

.cesium-button:disabled,
.cesium-button-disabled,
.cesium-button-disabled:focus,
.cesium-button-disabled:hover,
.cesium-button-disabled:active {
  background: #303336;
  border-color: #444;
  color: #646464; /* For text buttons */
  fill: #646464; /* For SVG buttons */
  box-shadow: none;
  cursor: default;
}

.cesium-button option {
  background-color: #000;
  color: #eee;
}

.cesium-button option:disabled {
  color: #777;
}

.cesium-button input,
.cesium-button label {
  cursor: pointer;
}

.cesium-button input {
  vertical-align: sub;
}

.cesium-toolbar-button {
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border-radius: 14%;
  padding: 0;
  vertical-align: middle;
  z-index: 0; /* Workaround for rounded raster image corners in Chrome */
}

.cesium-performanceDisplay-defaultContainer {
  position: absolute;
  top: 50px;
  right: 10px;
  text-align: right;
}

.cesium-performanceDisplay {
  background-color: rgba(40, 40, 40, 0.7);
  padding: 7px;
  border-radius: 5px;
  border: 1px solid #444;
  font: bold 12px sans-serif;
}

.cesium-performanceDisplay-fps {
  color: #e52;
}

.cesium-performanceDisplay-throttled {
  color: #a42;
}

.cesium-performanceDisplay-ms {
  color: #de3;
}
